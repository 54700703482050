if ('loading' in HTMLImageElement.prototype) {
  const triggerEvent = function (element, eventName) {
    var evt = document.createEvent('CustomEvent');

    evt.initCustomEvent(eventName, true, true, {});
    element.dispatchEvent(evt);
  };
  // Register IntersectionObserver
  const io = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        // Start playing the video if observation target is inside viewport
        triggerEvent(entry.target, 'lazybeforeunveil');
        io.unobserve(entry.target);
      }
    });
  });
  // Replace data-src with src for the Images to lazyload natively.
  let updateElements = function () {
    const elements = document.querySelectorAll('img.lazyload, video.lazyload');
    const regPicture = /^picture$/iu;

    elements.forEach((element) => {
      const parentNode = element.parentNode;
      const isPicture = parentNode && regPicture.test(parentNode.nodeName || '');

      element.classList.remove('lazyload');
      if (element.dataset.src) {
        element.loading = 'lazy';
        element.src = element.dataset.src;
        delete element.dataset.src;
      }
      if (isPicture) {
        const sources = parentNode.getElementsByTagName('source');

        for (let i = 0, len = sources.length; i < len; i++) {
          const source = sources[i];
          const sourceSrcset = source.dataset.srcset;

          if (sourceSrcset) {
            source.srcset = sourceSrcset;
            delete source.dataset.srcset;
          }
        }
      }
      io.observe(element);
    });
  };

  //initialize throttlePause variable outside throttle function
  let throttlePause;
  const throttle = (callback) => {
    //don't run the function if throttlePause is true
    if (throttlePause) return;
    //set throttlePause to true after the if condition. This allows the function to be run once
    throttlePause = true;

    //setTimeout runs the callback within the specified time
    setTimeout(() => {
      callback();

      //throttlePause is set to false once the function has been called, allowing the throttle function to loop
      throttlePause = false;
    }, 125);
  };

  let throttledUpdateElements = function() {
    throttle(updateElements);
  }

  if (window.MutationObserver) {
    new MutationObserver(throttledUpdateElements).observe(document.documentElement, {
      childList: true,
      subtree: true
    });
  } else {
    document.addEventListener('DOMNodeInserted', throttledUpdateElements, true);
    document.addEventListener('DOMAttrModified', throttledUpdateElements, true);
  }

  throttledUpdateElements();
} else {
  // Dynamically import LazySizes libraries.
  const lib = document.createElement('script');
  const config = document.createElement('script');

  lib.src = '/sites/aveda/themes/aveda_base/js/lib/lazysizes/lazysizes.min.js';
  config.src = '/sites/aveda/themes/aveda_base/js/site/lazysizes-config.min.js';
  document.head.appendChild(lib);
  document.body.appendChild(config);
}
